import React from "react";

const Contact = () => {
    return(
        <section id="contact" className="py-20 bg-gray-100">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl font-bold mb-8 text-gray-800">Contact Me</h2>
                <p className="text-xl mb-4 text-gray-600">
                Don’t be afraid to give up the good for the great.
                </p>

                <p className="text-xl mb-4 text-gray-600">
                ramaabhi62@gmail.com
                </p>
                
                <a href="mailto:sindhu.educourse@gmail.com" className="bg-slate-500 hover:bg-slate-500 text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-300">
                    Email
                </a>

            </div>

        </section>
    )
}

export default Contact;
