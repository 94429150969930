import React from "react";
import project1 from '../asset/project1.png';
import project2 from '../asset/project2.png';
import project3 from '../asset/project3.png';
import project4 from '../asset/project4.png';
import project5 from '../asset/project5.png';
import project6 from '../asset/project6.png';

const Projects = () => {
    return (
        <section id="projects" className="py-20 bg-white">
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-8">My Projects</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project1} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">UKIRAN CNC</h3>
                        <p className="text-gray-700">Membuat desain ukiran kayu untuk tempat ruko salah satu guru</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project2} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">TAMPILAN SPOTIFY</h3>
                        <p className="text-gray-700">Membuat tampilan music menggunakan aplikasi figma</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project3} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">TAMPILAN LOGIN</h3>
                        <p className="text-gray-700">Membuat tampilan login menggunakan aplikasi figma</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project4} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">WEB CUACA</h3>
                        <p className="text-gray-700">Tampilan sebuah web untuk mendeteksi cuaca di daerah</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project5} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">DESAIN KAOS</h3>
                        <p className="text-gray-700">Membuat Desain kaos untuk peserta magang KIDI</p>
                    </div>

                    <div className="bg-gray-100 p-4 rounded shadow-lg">
                        <img src={project6} alt="Samuel Wattimena" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2">ID CARD</h3>
                        <p className="text-gray-700">Membuat pesanan desain id card acara job fair SMKN 3 KENDAL</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects;
